import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';
import format from 'date-fns/format';
import { ReservationService } from '../reservation.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '@app/core';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { PMSIntegrationService } from './pms-integration.service';
import { Subject } from 'rxjs';
import { ClientService } from '@app/core/client.service';
import { ReplaceGuestReservationComponent } from './replace-guest-reservation/replace-guest-reservation.component';
import { addDays, subDays } from 'date-fns';
@Component({
  selector: 'app-hotel-reservation',
  templateUrl: './hotel-reservation.component.html',
  styleUrls: ['./hotel-reservation.component.scss']
})
export class HotelReservationComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  reservationFormGroup: FormGroup;
  isLoadingAutocomplete: boolean = false;
  // filteredGuests: any[] = [];
  restaurants: any = [];
  times: any[] = [];
  incompatibilities: any[] = [];
  areAllTablesFreeAtSelectedTime: boolean = true;
  clientId: number;
  minArrivalDate: Date;
  minDepartureDate: Date;
  // stayTimeIsNotSelected: boolean = true;
  // stayTimeErrorMsg = 'Bitte wählen Sie zuerst die Aufenthaltsdauer.';
  tags: any[];
  previousArrivalDate: Date;
  previousDepartureDate: Date;
  stayTimes = [
    { name: '30 Minuten', value: 30 },
    { name: '1 Stunde', value: 60 },
    { name: '1.5 Stunden', value: 90 },
    { name: '2 Stunden', value: 120 }
  ];
  private ngUnsubscribe: Subject<any> = new Subject();
  hotelReservations: any = [];
  totalLimt: number = 50;
  loadingHotelResOracle: boolean = false;
  loadingHotelResASA: boolean = false;
  copyAllItems: boolean = false;
  defaultTime: string;
  mealTypeMap: { [key: string]: string } = {
    breakfast: 'Frühstück',
    lunch: 'Mittagessen',
    dinner: 'Abendessen'
  };
  breakfastCounter: number = 1;
  lunchCounter: number = 1;
  dinnerCounter: number = 1;
  mealOptions = [
    { value: 'breakfast', label: 'Breakfast' },
    { value: 'HB', label: 'Half Board' },
    { value: 'FB', label: 'Full Board' }
  ];
  mealTypeOrder: { [key: string]: number } = {
    dinner: 1,
    lunch: 2,
    breakfast: 3
  };
  allHotelReservations: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private reservationService: ReservationService,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private pmsIntegrationService: PMSIntegrationService,
    private clientService: ClientService
  ) {}

  ngOnInit() {
    this.initForm();
    this.getTimes();
    this.subscriptions.push(
      this.reservationService.getAllHotelReservations().subscribe(
        res => {
          if (res) {
            this.allHotelReservations = res.map((item: any) => item.hotelReservation.bookingNumber);
          }
        },
        err => {
          this.allHotelReservations = [];
        }
      )
    );

    this.subscriptions.push(
      this.authService.getActiveClient().subscribe(data => {
        this.clientId = data.id;
      })
    );

    this.subscriptions.push(
      this.reservationService.getRooms().subscribe(rooms => {
        this.restaurants = rooms.filter(function(room: any) {
          return room.isActive == 1;
        });
      })
    );

    this.subscriptions.push(
      this.reservationService.getIncompatibilities().subscribe(incompatibilities => {
        this.incompatibilities = incompatibilities.data;
      })
    );

    // this.minArrivalDate = new Date();
    // this.minDepartureDate = new Date();

    this.subscriptions.push(
      this.reservationService.getTags().subscribe((tags: any) => {
        this.tags = tags;
      })
    );
  }

  createNumValues(number: number): any[] {
    const items: any[] = [];
    for (let i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  addNewReservationGroup(date: Date, mealType: any): FormGroup {
    const group = this.formBuilder.group({
      date: [date, Validators.required],
      time: [null, Validators.required],
      listOfTimes: [this.times, Validators.required],
      listOfRestaurants: [this.restaurants, Validators.required],
      restaurant: [null, Validators.required],
      table: [null, Validators.required],
      listOfTables: [[]],
      errorMessage: [''],
      isEditable: [false],
      mealType: [mealType, Validators.required],
      resIndex: [],
      tableLoader: false,
      exempt: [false]
    });

    // Watch for changes in `exempt`
    group.get('exempt').valueChanges.subscribe(exempt => {
      if (exempt) {
        group.get('date').clearValidators();
        group.get('time').clearValidators();
        group.get('listOfTimes').clearValidators();
        group.get('listOfRestaurants').clearValidators();
        group.get('restaurant').clearValidators();
        group.get('table').clearValidators();
        group.get('mealType').clearValidators();
      } else {
        group.get('date').setValidators(Validators.required);
        group.get('time').setValidators(Validators.required);
        group.get('listOfTimes').setValidators(Validators.required);
        group.get('listOfRestaurants').setValidators(Validators.required);
        group.get('restaurant').setValidators(Validators.required);
        group.get('table').setValidators(Validators.required);
        group.get('mealType').setValidators(Validators.required);
      }
      group.get('date').updateValueAndValidity({ emitEvent: false });
      group.get('time').updateValueAndValidity({ emitEvent: false });
      group.get('listOfTimes').updateValueAndValidity({ emitEvent: false });
      group.get('listOfRestaurants').updateValueAndValidity({ emitEvent: false });
      group.get('restaurant').updateValueAndValidity({ emitEvent: false });
      group.get('table').updateValueAndValidity({ emitEvent: false });
      group.get('mealType').updateValueAndValidity({ emitEvent: false });
    });

    return group;
  }

  addNewReservationFormAtIndex(date: Date, index: number, mealType: any = '') {
    (<FormArray>this.reservationForm).insert(index, this.addNewReservationGroup(date, mealType));
  }

  deleteReservationFromArray(index: number) {
    (<FormArray>this.reservationForm).removeAt(index);
  }

  displayFnName(guest: any): string {
    if (guest) {
      this.reservationFormGroup.get('incompatibilities').setValue(JSON.parse(guest.intolerance));
      return guest.name;
    }
  }

  createReservation(): void {
    // if everything is ok create new reservations
    if (this.reservationFormGroup.valid) {
      this.processReservations();
    }

    // check if input form is invalid without reservations form
    if (
      !this.reservationFormGroup.controls.name.valid ||
      !this.reservationFormGroup.controls.roomNumber.valid ||
      !this.reservationFormGroup.controls.reservationNumber.valid ||
      !this.reservationFormGroup.controls.arrivalDate.valid ||
      !this.reservationFormGroup.controls.departureDate.valid ||
      !this.reservationFormGroup.controls.stayTime.valid
    ) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    // if all input fields are valid except list of reservations show this confirm dialog
    if (!this.reservationFormGroup.controls.reservations.valid) {
      // Loop through all form controls and log the invalid ones
      // for (const controlName in this.reservationFormGroup.controls) {
      //   const control = this.reservationFormGroup.controls[controlName];

      //   if (control.invalid) {
      //     console.log(`❌ ${controlName} is invalid`);
      //     console.log(`   ➡ Current Value:`, control.value);

      //     // Check and log specific validation errors
      //     if (control.errors) {
      //       Object.keys(control.errors).forEach(errorKey => {
      //         console.log(`   🚨 Error: ${errorKey} -`, control.errors[errorKey]);
      //       });
      //     }

      //     // If it's a FormArray (like reservations), check its children
      //     if (control instanceof FormArray) {
      //       console.log(`   🔎 Inspecting FormArray: ${controlName}`);
      //       control.controls.forEach((group, index) => {
      //         if (group.invalid) {
      //           console.log(`   ❌ reservations[${index}] is invalid`);
      //           console.log(`      ➡ Current Value:`, group.value);

      //           if (group.errors) {
      //             Object.keys(group.errors).forEach(errorKey => {
      //               console.log(`      🚨 Error: ${errorKey} -`, group.errors[errorKey]);
      //             });
      //           }

      //           // Check each FormGroup inside the FormArray
      //           if (group instanceof FormGroup) {
      //             Object.keys(group.controls).forEach(childName => {
      //               const childControl = group.get(childName);
      //               if (childControl && childControl.invalid) {
      //                 console.log(`      ❌ Field '${childName}' inside reservations[${index}] is invalid`);
      //                 console.log(`         ➡ Current Value:`, childControl.value);
      //                 if (childControl.errors) {
      //                   Object.keys(childControl.errors).forEach(errorKey => {
      //                     console.log(`         🚨 Error: ${errorKey} -`, childControl.errors[errorKey]);
      //                   });
      //                 }
      //               }
      //             });
      //           }
      //         }
      //       });
      //     }
      //   } else {
      //     console.log(`✅ ${controlName} is valid`);
      //   }
      // }

      const modalRef = this.modalService.open(ConfirmModalComponent);
      modalRef.componentInstance.title = 'Nicht alle Reservierungen wurden erfüllt';
      modalRef.componentInstance.message = `Es gibt noch Tage ohne Reservierung. Trotzdem speichern?`;
      modalRef.componentInstance.showInfo = false;
      modalRef.componentInstance.buttonText = 'Ja';
      modalRef.result.then(
        result => {
          if (result === 'ok') {
            this.processReservations();
          }
        },
        () => {}
      );
    }
  }

  searchFreeTables(index: number, previousTable: number = null) {
    const selectedRestaurantId = this.reservationForm.value[index].restaurant;
    const time = this.reservationForm.value[index].time;
    if (selectedRestaurantId && time) {
      this.updateFormGroupValue('reservations', { tableLoader: true }, index);
      if (this.reservationForm.value[index].table) {
        this.reservationForm.controls[index].get('table').patchValue(null, { emitEvent: false });
        this.updateFormGroupValue('reservations', { listOfTables: [] }, index);
        this.updateFormGroupValue('reservations', { errorMessage: '' }, index);
      }
      const data = this.reservationFormGroup.value;
      const res = this.reservationForm.value[index];

      this.subscriptions.push(
        this.reservationService
          .checkFreeTables(
            res.date,
            time,
            data.numberOfPersons,
            data.stayTime.value,
            true,
            true,
            '',
            selectedRestaurantId
          )
          .subscribe(response => {
            const selectedRestaurant = response[0];
            this.fillTableArrayWithAwailableTables(selectedRestaurant, index, previousTable);
          })
      );
    }
  }

  fillTableArrayWithAwailableTables(selectedRestaurant: any, index: number, previousTable: number): void {
    const tables: any[] = [];
    if (selectedRestaurant && selectedRestaurant.tables) {
      for (let i = 0; i < selectedRestaurant.tables.length; i++) {
        const element = selectedRestaurant.tables[i];

        let isNotAvailable = false;
        let msg = 'Der Tisch ist frei';

        if (element.isFree === false) {
          isNotAvailable = true;
          msg = 'Der Tisch ist besetzt';
        } else if (parseInt(this.reservationFormGroup.get('numberOfPersons').value) > element.seats) {
          isNotAvailable = true;
          msg = `Max ${element.seats} Personen`;
        }
        tables.push({ table: element, isNotAvailable, msg });
      }
    }
    if (previousTable && tables.length > 0) {
      const availableTables = tables.filter(
        (element: any) =>
          element.isFree && parseInt(this.reservationFormGroup.get('numberOfPersons').value) <= element.seats
      );
      const tableExist = availableTables.some((item: any) => item.id === previousTable);
      if (!tableExist) {
        this.updateFormGroupValue('reservations', { errorMessage: 'Table is not free' }, index);
      } else {
        this.updateFormGroupValue('reservations', { errorMessage: '' }, index);
      }
    }
    this.updateFormGroupValue('reservations', { listOfTables: tables }, index);
    this.updateFormGroupValue('reservations', { tableLoader: false }, index);
  }

  onNumberOfPersonsChange() {
    for (let i = 0; i < this.reservationForm.value.length; i++) {
      const element = this.reservationForm.value[i];
      if (element.restaurant) {
        this.searchFreeTables(i);
      }
    }
  }

  updateFormGroupValue(formGroupName: string, value: any, index: number): void {
    (<FormArray>this.reservationFormGroup.controls[formGroupName]).at(index).patchValue(value, { emitEvent: false });
  }

  processReservations(): void {
    const reservationFormData = this.reservationFormGroup.value;
    const reservationArray = reservationFormData.reservations;
    const reservations = [];
    for (let i = 0; i < reservationArray.length; i++) {
      const tempObject: any = {};
      const element = reservationArray[i];
      if (element.date && element.restaurant && element.table) {
        tempObject.reservedFor = format(element.date, 'YYYY-MM-DD') + ' ' + element.time + ':00:00';
        tempObject.room = element.restaurant;
        tempObject.table = element.table;
        reservations.push(tempObject);
      }
    }

    if (reservations.length === 0) {
      this.snackBar.open('Mindestens eine Reservierung muss abgeschlossen sein', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }
    const reservation = {
      name: reservationFormData.name,
      roomNumber: reservationFormData.roomNumber,
      reservationNumber: reservationFormData.reservationNumber,
      arrivalDate: reservationFormData.arrivalDate,
      departureDate: reservationFormData.departureDate,
      selectedTags: reservationFormData.selectedTags,
      numberOfPersons: reservationFormData.numberOfPersons,
      numberOfKids: reservationFormData.numberOfKids,
      numberOfHighChairs: reservationFormData.numberOfHighChairs,
      incompatibilities: reservationFormData.incompatibilities,
      notes: reservationFormData.notes,
      reservations: reservations,
      stayTime: reservationFormData.stayTime.value,
      firstName: reservationFormData.firstName,
      email: reservationFormData.email,
      phone: reservationFormData.phone,
      createdAt: new Date()
    };
    this.subscriptions.push(
      this.reservationService.addMultipleReservations(reservation).subscribe(
        (res: any) => {
          if (res) {
            this.snackBar.open('Sie haben erfolgreich gebucht.', '', {
              duration: 3000,
              panelClass: ['snackbar-success']
            });
            this.initForm();
          }
        },
        err => {
          if (err.status === 422) {
            this.snackBar.open(err.error.msg, '', {
              duration: 3000,
              panelClass: ['snackbar-error']
            });
          }
        }
      )
    );
  }

  initForm(): void {
    this.reservationFormGroup = this.formBuilder.group({
      name: [null, Validators.required],
      roomNumber: [null, Validators.required],
      reservationNumber: ['', Validators.required],
      arrivalDate: [null, Validators.required],
      departureDate: [null, Validators.required],
      stayTime: new FormControl(null, Validators.required),
      selectedTags: [[]],
      numberOfPersons: [1, Validators.required],
      numberOfKids: [0, Validators.required],
      numberOfHighChairs: [0, Validators.required],
      incompatibilities: [null],
      notes: [null],
      reservations: this.formBuilder.array([]),
      boardType: [null],
      firstName: [null],
      email: [null],
      phone: [null]
    });
    setTimeout(() => {
      this.reservationFormGroup.controls['stayTime'].setValue(this.stayTimes[1]);
    }, 500);
  }

  get reservationForm() {
    return <FormArray>this.reservationFormGroup.get('reservations');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  getHotelReservationOracle() {
    this.loadingHotelResOracle = true;
    this.subscriptions.push(
      this.pmsIntegrationService.getHotelReservationsOracle().subscribe(
        res => {
          if (res.data) {
            this.hotelReservations = res.data.map((item: any) => {
              console.log('packages', item.packages);
              console.log('guestMessages', item.guestMessages);
              let hotelReservation: any = {};
              hotelReservation.id = item.reservationIdList[0].id;
              hotelReservation.arrivalDate = item.roomStay.arrivalDate;
              hotelReservation.departureDate = item.roomStay.departureDate;
              hotelReservation.guestCount = item.roomStay.adultCount + item.roomStay.childCount;
              // hotelReservation.guestCount = item.roomStay.adultCount;
              hotelReservation.childCount = item.roomStay.childCount;
              hotelReservation.roomNo = item.roomStay.roomId;
              hotelReservation.guestInfo = {
                name: item.reservationGuest.surname,
                firstName: item.reservationGuest.givenName,
                email: item.reservationGuest.email,
                phoneNo: item.reservationGuest.phoneNumber
              };
              return hotelReservation;
            });
          }
          console.log('hello1', this.hotelReservations);
          this.loadingHotelResOracle = false;
        },
        err => {
          this.loadingHotelResOracle = false;
          const errMsg = err && err.error.msg ? err.error.msg : 'Something went wrong';
          this.snackBar.open(errMsg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      )
    );
  }

  getHotelReservationASA() {
    this.loadingHotelResASA = true;
    this.subscriptions.push(
      this.pmsIntegrationService.getHotelReservationsASA().subscribe(
        res => {
          if (res.data) {
            this.hotelReservations = res.data.map((item: any) => {
              let hotelReservation: any = {};
              hotelReservation.id = item.number;
              const roomReservations = item.roomReservations.roomReservation[0];

              hotelReservation.arrivalDate = roomReservations.arrival;
              hotelReservation.departureDate = roomReservations.departure;

              hotelReservation.guestCount = roomReservations.roomGuests
                ? roomReservations.roomGuests.roomGuest.length
                : 2;
              // hotelReservation.guestCount = item.roomStay.adultCount;
              // hotelReservation.childCount = item.roomStay.childCount;
              hotelReservation.roomNo = roomReservations.room.number;
              hotelReservation.guestInfo = {
                name: roomReservations.roomGuests ? roomReservations.roomGuests.roomGuest[0].guest.lastName : 'No name',
                firstName: roomReservations.roomGuests ? roomReservations.roomGuests.roomGuest[0].guest.firstName : ''
                // email: item.reservationGuest.email,
                // phoneNo: item.reservationGuest.phoneNumber
              };
              hotelReservation.boardType = roomReservations.roomGuests
                ? roomReservations.roomGuests.roomGuest[0].boardCode
                : 'HB';
              hotelReservation.placed = this.allHotelReservations.includes(hotelReservation.id.toString());
              return hotelReservation;
            });
          }
          console.log('hello1', this.hotelReservations);
          this.loadingHotelResASA = false;
        },
        err => {
          this.loadingHotelResASA = false;
          const errMsg = err && err.error.msg ? err.error.msg : 'Something went wrong';
          this.snackBar.open(errMsg, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      )
    );
  }

  placeGuest(reservation: any) {
    this.reservationFormGroup.get('name').setValue(reservation.guestInfo.name);
    this.reservationFormGroup.get('roomNumber').setValue(reservation.roomNo);
    this.reservationFormGroup.get('reservationNumber').setValue(reservation.id);
    this.reservationFormGroup.get('arrivalDate').setValue(moment(reservation.arrivalDate));
    this.reservationFormGroup.get('departureDate').setValue(moment(reservation.departureDate));
    this.reservationFormGroup.get('numberOfPersons').setValue(reservation.guestCount);
    this.reservationFormGroup.get('numberOfKids').setValue(reservation.childCount ? reservation.childCount : 0);
    this.reservationFormGroup.get('boardType').setValue(reservation.boardType);
    this.reservationFormGroup.get('firstName').setValue(reservation.guestInfo.firstName);
    this.reservationFormGroup.get('email').setValue(reservation.guestInfo.email);
    this.reservationFormGroup.get('phone').setValue(reservation.guestInfo.phone);
    (this.reservationForm as FormArray).clear();
    this.checkIsSelectedTimeIntervalValid();
  }

  callCopyAllItems(i: any, mealType: string) {
    this.copyAllItems = true;

    // Find the first row of the given mealType
    // const firstElement = this.reservationForm.value.find((item: any) => item.mealType === mealType);
    // if (!firstElement) return; // If no such row, exit

    const firstElement = this.reservationForm.value[i];

    // Iterate through all rows and update only matching mealType rows
    this.reservationForm.value.forEach((element: any, index: any) => {
      if (element.mealType === mealType && element.resIndex && element.resIndex != 1) {
        this.updateFormGroupValue('reservations', { listOfTimes: firstElement.listOfTimes }, index);
        this.updateFormGroupValue('reservations', { listOfRestaurants: firstElement.listOfRestaurants }, index);
        this.updateFormGroupValue('reservations', { listOfTables: firstElement.listOfTables }, index);
        this.updateFormGroupValue('reservations', { time: firstElement.time }, index);
        this.updateFormGroupValue('reservations', { restaurant: firstElement.restaurant }, index);
        this.updateFormGroupValue('reservations', { tableLoader: true }, index);
        (async () => {
          const isAvailable = await this.checkTableAvailability(firstElement.time, firstElement.table, index);
          if (!isAvailable) {
            this.updateFormGroupValue('reservations', { errorMessage: 'Table is not free' }, index);
          } else {
            this.updateFormGroupValue('reservations', { errorMessage: '' }, index);
          }
          this.updateFormGroupValue('reservations', { table: firstElement.table }, index);
          this.updateFormGroupValue('reservations', { tableLoader: false }, index);
        })();
      }
    });
  }

  checkTableAvailability(time: any, table: any, index: any): Promise<boolean> {
    return new Promise(resolve => {
      const selectedRestaurantId = this.reservationForm.value[index].restaurant;
      const data = this.reservationFormGroup.value;
      const res = this.reservationForm.value[index];

      this.subscriptions.push(
        this.reservationService
          .checkFreeTables(
            res.date,
            time,
            data.numberOfPersons,
            data.stayTime.value,
            true,
            true,
            '',
            selectedRestaurantId
          )
          .subscribe(response => {
            const selectedRestaurant = response[0];
            const availableTables = selectedRestaurant.tables.filter(
              (element: any) =>
                element.isFree && parseInt(this.reservationFormGroup.get('numberOfPersons').value) <= element.seats
            );
            const tableExist = availableTables.some((item: any) => item.id === table);
            resolve(tableExist);
          })
      );
    });
  }

  getTimes() {
    this.defaultTime = moment().format('HH:mm');
    this.times = [];
    let tStart = 0;
    const interval = 15;
    for (let i = 0; tStart < 24 * 60; i++) {
      const hh = Math.floor(tStart / 60); // getting hours of day in 0-24 format
      const mm = tStart % 60; // getting minutes of the hour in 0-55 format

      const timeObj = {
        time: ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2),
        isClosed: true
      };
      this.times.push(timeObj);

      console.log('get times');
      const hhNext = Math.floor((tStart + interval) / 60); // getting hours of day in 0-24 format
      const mmNext = (tStart + interval) % 60; // getting minutes of the hour in 0-55 format
      const dateObjNext = new Date();
      dateObjNext.setHours(hhNext);
      dateObjNext.setMinutes(mmNext);

      const dateObj = new Date();
      dateObj.setHours(hh);
      dateObj.setMinutes(mm);

      const defaultTimeDateObj = new Date();
      const defaultTimeSplit = this.defaultTime.split(':');
      defaultTimeDateObj.setHours(+defaultTimeSplit[0]);
      defaultTimeDateObj.setMinutes(+defaultTimeSplit[1]);

      if (dateObj < defaultTimeDateObj && dateObjNext > defaultTimeDateObj) {
        const defaultTimeObj = {
          time: this.defaultTime,
          isClosed: false
        };
      }

      tStart = tStart + interval;
    }
    // Check opening hours and append Closed label to times which are closed
    this.clientService
      .getOpeningHourOfDay(moment(this.reservationFormGroup.value.date).format('YYYY-MM-DD'))
      .takeUntil(this.ngUnsubscribe)
      .subscribe(async (openingHours: any) => {
        if (openingHours.length) {
          // Loop trough all entries to check time
          await openingHours.reduce(
            (prev: any, oh: any) =>
              prev.then(async () => {
                this.times = await this._checkTimesWithOpeningHours(oh.tFrom, oh.tTo, oh.closed);
              }),
            Promise.resolve()
          );
        } else {
          // No opening hours for this day, so its closed
          const newTimes = await this.times.map((time: any) => {
            time.isClosed = true;
            return time;
          });
          this.times = [...newTimes];
        }
      });
  }

  private _checkTimesWithOpeningHours(tFrom: string, tTo: string, closed: boolean = false) {
    const from = moment(tFrom, 'HH:mm');
    const to = moment(tTo, 'HH:mm');

    return Promise.all(
      this.times.map((t: any) => {
        if (t.isClosed && !closed) {
          const time = moment(t.time, 'HH:mm');
          if (from.isBefore(to)) {
            t.isClosed = !(
              time.isBetween(from, to) ||
              time.format('HH:mm') === from.format('HH:mm') ||
              time.format('HH:mm') === to.format('HH:mm')
            );
          } else {
            t.isClosed = time.isBetween(to, from);
          }
          return t;
        } else if (!t.isClosed && closed) {
          const time = moment(t.time, 'HH:mm');
          if (from.isBefore(to)) {
            t.isClosed =
              time.isBetween(from, to) ||
              time.format('HH:mm') === from.format('HH:mm') ||
              time.format('HH:mm') === to.format('HH:mm');
          } else {
            t.isClosed = !time.isBetween(to, from);
          }
          return t;
        } else {
          // Time oh was alredy set
          return t;
        }
      })
    );
  }

  changeBoardOption() {
    if (this.reservationFormGroup.value.arrivalDate && this.reservationFormGroup.value.departureDate) {
      // Clear existing reservations and insert in correct order
      (this.reservationForm as FormArray).clear();
      this.checkIsSelectedTimeIntervalValid();
    }
  }
  checkIsSelectedTimeIntervalValid(): void {
    let reservations = this.reservationForm as FormArray;
    let arrivalDate = new Date(this.reservationFormGroup.value.arrivalDate);
    arrivalDate.setHours(0, 0, 0, 0);
    let departureDate = new Date(this.reservationFormGroup.value.departureDate);
    departureDate.setHours(0, 0, 0, 0);
    const boardType = this.reservationFormGroup.value.boardType;

    if (reservations.length > 0) {
      if (arrivalDate.getTime() > this.previousArrivalDate.getTime()) {
        this.removeReservationsBefore(arrivalDate);
      } else if (arrivalDate.getTime() < this.previousArrivalDate.getTime()) {
        this.addElementsToReservations(arrivalDate, subDays(this.previousArrivalDate, 1), boardType);
      }

      if (departureDate.getTime() < this.previousDepartureDate.getTime()) {
        this.removeReservationsAfter(departureDate);
      } else if (departureDate.getTime() > this.previousDepartureDate.getTime()) {
        this.addElementsToReservations(addDays(this.previousDepartureDate, 1), departureDate, boardType);
      }
    } else {
      if (arrivalDate && departureDate && departureDate.getTime() > arrivalDate.getTime()) {
        this.addElementsToReservations(arrivalDate, departureDate, boardType);
      }
    }
    this.previousArrivalDate = new Date(this.reservationFormGroup.value.arrivalDate);
    this.previousArrivalDate.setHours(0, 0, 0, 0);
    this.previousDepartureDate = new Date(this.reservationFormGroup.value.departureDate);
    this.previousDepartureDate.setHours(0, 0, 0, 0);
  }

  /**
   * Adds reservations for **future dates only**, in correct order:
   * 1. All breakfast rows for all future dates
   * 2. All lunch rows for all future dates (if full board)
   * 3. All dinner rows for all future dates
   */
  addElementsToReservations(startDate: Date, endDate: Date, boardType: string) {
    let currentDate = new Date(startDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dinnerReservations: FormGroup[] = [];
    const lunchReservations: FormGroup[] = [];
    const breakfastReservations: FormGroup[] = [];

    while (currentDate.getTime() <= endDate.getTime()) {
      if (currentDate.getTime() >= today.getTime()) {
        // Only add future dates
        if (boardType === 'HB' || boardType === 'FB') {
          dinnerReservations.push(this.addNewReservationGroup(new Date(currentDate), 'dinner'));
        }
        if (boardType === 'FB') {
          lunchReservations.push(this.addNewReservationGroup(new Date(currentDate), 'lunch'));
        }
        if (boardType === 'breakfast' || boardType === 'HB' || boardType === 'FB') {
          breakfastReservations.push(this.addNewReservationGroup(new Date(currentDate), 'breakfast'));
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    // this.insertReservationsByMealType([...dinnerReservations, ...lunchReservations, ...breakfastReservations]);

    // [...dinnerReservations, ...lunchReservations, ...breakfastReservations].forEach(res =>
    //   (this.reservationForm as FormArray).push(res)
    // );

    const existingReservationsFormControl = (this.reservationForm as FormArray).controls;
    const existingReservations = existingReservationsFormControl.map(control => control as FormGroup);
    const allReservations: any = [
      ...existingReservations,
      ...dinnerReservations,
      ...lunchReservations,
      ...breakfastReservations
    ];
    this.sortReservationAndFilterReservations(allReservations);
  }

  sortReservationAndFilterReservations(allReservations: any) {
    allReservations.sort((a: any, b: any) => {
      const mealOrderA = this.mealTypeOrder[a.value.mealType] || 99;
      const mealOrderB = this.mealTypeOrder[b.value.mealType] || 99;

      if (mealOrderA !== mealOrderB) {
        return mealOrderA - mealOrderB;
      }
      const dateA = new Date(a.value.date).getTime();
      const dateB = new Date(b.value.date).getTime();

      return dateA - dateB;
    });

    (this.reservationForm as FormArray).clear();

    allReservations.forEach((res: any) => {
      const mealType = res.get('mealType').value;
      const date = res.get('date').value;
      date.setHours(0, 0, 0, 0);
      const arrivalDate = new Date(this.reservationFormGroup.value.arrivalDate);
      arrivalDate.setHours(0, 0, 0, 0);
      const departureDate = new Date(this.reservationFormGroup.value.departureDate);
      departureDate.setHours(0, 0, 0, 0);

      let exempt = false;
      if (date.getTime() == arrivalDate.getTime()) {
        // console.log('firstday', date);
        exempt = mealType !== 'dinner';
      }
      if (date.getTime() == departureDate.getTime()) {
        // console.log('lastday', date);
        exempt = mealType !== 'breakfast';
      }
      res.get('exempt').setValue(exempt);
      (this.reservationForm as FormArray).push(res);
    });
    this.updateFirstEditableForMealType();
  }

  // To find the last index of a given mealType
  findLastMealTypeIndex(mealType: string) {
    const existingReservations = this.reservationForm.value;
    let lastIndex = -1;
    existingReservations.forEach((res: any, index: any) => {
      if (res.mealType === mealType) {
        lastIndex = index;
      }
    });
    return lastIndex;
  }

  // Function to insert reservations after the last occurrence of their type
  insertReservationsByMealType(newReservations: FormGroup[]) {
    newReservations.forEach(newRes => {
      const mealType = newRes.get('mealType').value;
      const date = newRes.get('date').value;
      date.setHours(0, 0, 0, 0);
      const arrivalDate = new Date(this.reservationFormGroup.value.arrivalDate);
      arrivalDate.setHours(0, 0, 0, 0);
      const departureDate = new Date(this.reservationFormGroup.value.departureDate);
      departureDate.setHours(0, 0, 0, 0);

      if (date.getTime() == arrivalDate.getTime()) {
        console.log('firstday', date);
        if (mealType !== 'dinner') {
          newRes.get('exempt').setValue(true);
        }
      }
      if (date.getTime() == departureDate.getTime()) {
        console.log('lastday', date);
        if (mealType !== 'breakfast') {
          newRes.get('exempt').setValue(true);
        }
      }
      const lastIndex = this.findLastMealTypeIndex(mealType);

      if (lastIndex === -1) {
        (this.reservationForm as FormArray).push(newRes);
      } else {
        (this.reservationForm as FormArray).insert(lastIndex + 1, newRes);
      }
    });
  }

  /**
   * Removes reservations before a given date.
   */
  removeReservationsBefore(date: Date) {
    const reservations = this.reservationForm as FormArray;
    for (let i = reservations.length - 1; i >= 0; i--) {
      if (new Date(reservations.at(i).value.date).getTime() < date.getTime()) {
        reservations.removeAt(i);
        this.updateFirstEditableForMealType();
      }
    }
  }

  /**
   * Removes reservations after a given date.
   */
  removeReservationsAfter(date: Date) {
    const reservations = this.reservationForm as FormArray;
    for (let i = reservations.length - 1; i >= 0; i--) {
      if (new Date(reservations.at(i).value.date).getTime() > date.getTime()) {
        reservations.removeAt(i);
        this.updateFirstEditableForMealType();
      }
    }
  }

  // isFirstRowEmpty(mealType: string): boolean {
  //   const firstRow = this.reservationForm.value.find((item: any) => item.mealType === mealType);
  //   return !firstRow.table;
  // }

  // isFirstRowOfMealType(mealType: string, index: number): boolean {
  //   return this.reservationForm.value.findIndex((item: any) => item.mealType === mealType) === index;
  // }

  updateFirstEditableForMealType() {
    this.breakfastCounter = 1;
    this.lunchCounter = 1;
    this.dinnerCounter = 1;
    let defaultTime = null;

    const processedMealTypes: string[] = [];

    for (let i = 0; i < this.reservationForm.value.length; i++) {
      const row = this.reservationForm.value[i];
      if (!row.exempt) {
        this.updateFormGroupValue('reservations', { resIndex: this.getMealTypeCounter(row.mealType) }, i);

        // If the mealType is not in the processed array, it's the first occurrence
        if (!processedMealTypes.includes(row.mealType)) {
          this.updateFormGroupValue('reservations', { isEditable: true }, i);
          processedMealTypes.push(row.mealType); // Add the mealType to the processed list
          if (row.mealType === 'breakfast') {
            const foundTime = this.times.find(t => t.time === '08:00');
            defaultTime = foundTime ? foundTime.time : null;
          } else if (row.mealType === 'lunch') {
            const foundTime = this.times.find(t => t.time === '13:00');
            defaultTime = foundTime ? foundTime.time : null;
          } else if (row.mealType === 'dinner') {
            const foundTime = this.times.find(t => t.time === '19:00');
            defaultTime = foundTime ? foundTime.time : null;
          }
          this.updateFormGroupValue('reservations', { time: defaultTime }, i);
        } else {
          this.updateFormGroupValue('reservations', { isEditable: false }, i);
        }
      }
    }
  }
  getMealTypeCounter(mealType: string): number {
    if (mealType === 'breakfast') {
      return this.breakfastCounter++;
    } else if (mealType === 'lunch') {
      return this.lunchCounter++;
    } else if (mealType === 'dinner') {
      return this.dinnerCounter++;
    }
    return 0;
  }

  editReservation(i: any) {
    this.updateFormGroupValue('reservations', { isEditable: true }, i);
    this.searchFreeTables(i);
  }

  replaceGuestReservation(index: any) {
    const element = this.reservationForm.value[index];
    const allDataList = {
      listOfRestaurants: element.listOfRestaurants,
      listOfTimes: element.listOfTimes
    };
    const reservedFor = format(element.date, 'YYYY-MM-DD') + ' ' + element.time;
    this.reservationService
      .fetchExistingGuestReservation(reservedFor, this.reservationFormGroup.value.stayTime, element.table)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        (reservation: any) => {
          if (reservation) {
            const modalRef = this.modalService.open(ReplaceGuestReservationComponent, {
              windowClass: 'onboarding-modal',
              size: 'lg'
            });
            modalRef.componentInstance.reservation = reservation;
            modalRef.componentInstance.allDataList = allDataList;
            modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
              this.reservationService.replaceGuestReservation(receivedEntry).then((response: any) => {
                this.searchFreeTables(index, element.table);
                const snackBarRef = this.snackBar.open('Erfolgreich.', 'Ok', {
                  duration: 3000,
                  panelClass: ['snackbar-success']
                });
                modalRef.close();
              });
            });
          }
        },
        err => {
          console.error('Error fetching reservation', err);
        }
      );
  }

  onTableChanged(index: any) {
    const element = this.reservationForm.value[index];
    const checkTableFree = element.listOfTables.find((item: any) => item.table.id === element.table);
    if (checkTableFree.isNotAvailable) {
      this.updateFormGroupValue('reservations', { errorMessage: 'Table is not free' }, index);
    } else {
      this.updateFormGroupValue('reservations', { errorMessage: '' }, index);
    }
  }
}

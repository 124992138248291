import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';
import { AuthenticationService, CredentialsService } from '@app/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class PMSIntegrationService {
  private accessToken: string | null = null;
  private expiresAt: number | null = null;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private credentialsService: CredentialsService
  ) {}

  fetchAccessToken() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/generate-oauth-token-oracle/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          const { access_token, expires_in } = response.data;
          this.setToken(access_token, expires_in);
          return access_token;
        }),
        catchError(error => {
          console.error('Error fetching access token:', error);
          return throwError(() => error);
        })
      );
  }

  // Set token in local stoarge
  private setToken(accessToken: string, expiresIn: number): void {
    const bufferTime = 5 * 60 * 1000; // 5 minutes in milliseconds
    this.accessToken = accessToken;
    this.expiresAt = Date.now() + expiresIn * 1000 - bufferTime; // Subtract buffer time
    localStorage.setItem('oracleAccessToken', accessToken);
    localStorage.setItem('oracleAcessTokenExpiry', this.expiresAt.toString());
  }

  // Get token from local storage
  private loadTokenFromStorage(): void {
    const token = localStorage.getItem('oracleAccessToken');
    const expiresAt = localStorage.getItem('oracleAcessTokenExpiry');
    if (token && expiresAt && Date.now() < Number(expiresAt)) {
      this.accessToken = token;
      this.expiresAt = Number(expiresAt);
    } else {
      this.clearToken();
    }
  }

  // Clear token from storage
  private clearToken(): void {
    this.accessToken = null;
    this.expiresAt = null;
    localStorage.removeItem('oracleAccessToken');
    localStorage.removeItem('oracleAcessTokenExpiry');
  }

  // Check if token is valid
  private isTokenValid(): boolean {
    this.loadTokenFromStorage();
    const isValid = !!this.accessToken && !!this.expiresAt && Date.now() < this.expiresAt;
    return isValid;
  }

  // Get a valid token (fetch new if needed)
  public getValidToken(): Observable<string> {
    if (this.isTokenValid()) {
      return of(this.accessToken!);
    } else {
      return this.fetchAccessToken();
    }
  }

  // Fetch hotel reservations
  getHotelReservationsOracle(
    hotelId: string = '',
    limit: number = 10,
    optedForCommunication: boolean = false
  ): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    const params = {
      limit: limit.toString(),
      optedForCommunication: optedForCommunication.toString()
    };

    return this.getValidToken().pipe(
      switchMap(token => {
        const params = {
          limit: limit.toString(),
          optedForCommunication: optedForCommunication.toString()
        };

        return this.http.post(
          `${environment.serverUrl}reservation/get-hotel-reservations-oracle/${this.authService.activeClientId}`,
          { token },
          { headers, params }
        );
      })
      // catchError(error => {
      //   console.error('Error fetching reservations:', error);
      //   return throwError(() => error);
      // })
    );

    // return this.http
    //   .get<any>(environment.serverUrl + `reservation/get-hotel-reservations-oracle/` + this.authService.activeClientId, {
    //     headers, params
    //   })
    //   .pipe(
    //     map((response: any) => {
    //       return response
    //     }),
    //     catchError((error) => {
    //       console.error('Error fetching access token:', error);
    //       return throwError(() => error);
    //     })
    //   );
  }

  getHotelReservationsASA() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `reservation/get-hotel-reservations-asa/` + this.authService.activeClientId, {
        headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
        // catchError(error => {
        //   console.error('Error fetching access token:', error);
        //   return throwError(() => error);
        // })
      );
  }
}

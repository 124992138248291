import { Component, OnInit } from '@angular/core';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { EventsService } from '@app/events/events.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { MatSnackBar } from '@angular/material';

import * as jspdf from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

import moment from 'moment';
import { AccountingService } from '@app/accounting/accounting.service';
moment.locale('de');

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss']
})
export class PayoutComponent implements OnInit {
  payoutData: any;
  showPayoutId: number;
  payout: {
    id: number;
    status: string;
    paidAt: any;
    createdAt: any;
    value: number;
  };

  orders: any[] = [];
  ordersCsv: any = {};
  creds: any = {};
  loading: boolean = false;

  pdfSums: any = {
    bar: 0,
    online: 0,
    commission: 0
  };
  date: any = {
    startDate: '',
    endDate: ''
  };
  now: any = new Date();
  csvOptions: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: ['ID', 'Datum', 'Uhrzeit', 'Betrag', 'Gebühr', 'Reserviert für', 'Gast', 'Typ', 'Kontostand'],
    showTitle: false,
    title: '',
    useBom: false,
    removeNewLines: true,
    keys: ['id', 'dateFormatted', 'timeFormatted', 'subTotal', 'commissionFee', 'besteller', 'typeText', 'balance']
  };
  billingCase: any = { iban: '', elv: 0 };
  requestPayoutProcess: boolean = false;

  constructor(
    private gastropayService: GastroPayService,
    private clientResolver: ClientResolverService,
    private credentialsService: CredentialsService,
    private accountingService: AccountingService,
    private eventsService: EventsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getPayouts();
    this.getBillingcase();
    this.creds = this.credentialsService.getCredentials();
  }

  getBillingcase() {
    this.accountingService.getBillingcase().subscribe((data: any) => {
      this.billingCase = data || { iban: '', elv: 0 };
    });
  }

  getPayouts() {
    this.gastropayService.getPayouts('/tickets').subscribe((payoutData: any) => {
      console.log(88, payoutData);
      this.payoutData = payoutData;
      if (payoutData.payouts) {
        payoutData.payouts.reverse();
      }
      this.requestPayoutProcess = false;
    });
  }

  requestPayout() {
    this.requestPayoutProcess = true;
    this.eventsService.requestPayout('tickets').subscribe((payoutData: any) => {
      this.getPayouts();
    });
  }

  downloadPdf(payout: any) {
    this.loading = true;
    this.pdfSums = {
      bar: 0,
      online: 0,
      commission: 0
    };
    this.orders = [];
    this.payout = payout || { id: 0 };
    this.eventsService.getTicketTransactions(payout.id).subscribe(orders => {
      orders.reverse();
      let tempOrder: any[] = [];
      orders.forEach((order: any) => {
        let totalSumEvent = 0;
        if (order.ticketOrders && order.ticketOrders.tickets && order.ticketOrders.tickets[0]) {
          let eventKey =
            order.ticketOrders.tickets[0].eventDetails.name +
            ' ' +
            (order.ticketOrders.tickets[0].dateInfo && order.ticketOrders.tickets[0].dateInfo.startsAtDay
              ? moment(order.ticketOrders.tickets[0].dateInfo.startsAtDay).format('DD.MM.YYYY')
              : '');
          if (order.payoutId === payout.id && order.type !== 'payout') {
            this.date.startDate = moment(order.createdAt).format('DD.MM.YYYY');
            this.date.endDate = this.date.endDate ? this.date.endDate : moment(order.createdAt).format('DD.MM.YYYY');

            if (tempOrder[eventKey] == undefined) {
              tempOrder[eventKey] = [];
            }
            let tempOrder1 = [];
            tempOrder1.push(moment(order.createdAt).format('DD.MM.YYYY'));
            tempOrder1.push(`${order.ticketOrders.firstName} ${order.ticketOrders.lastName}`);
            tempOrder1.push(order.ticketOrders.tickets.length);
            tempOrder1.push(
              (order.type == 'refund' ? '-' : '') +
                this.returnPrice(order.value)
                  .toFixed(2)
                  .replace('.', ',') +
                ' €'
            );
            tempOrder[eventKey].push(tempOrder1);
            totalSumEvent += order.value * (order.type == 'refund' ? -1 : 1);
            tempOrder1.push(totalSumEvent);

            this.pdfSums.commission += this.returnPrice(order.fee);
            this.pdfSums.online += this.returnPrice(order.value * (order.type == 'refund' ? -1 : 1));
          }
        }
      });
      this.orders.push(tempOrder);
      setTimeout(
        function(_this: any) {
          _this.generatePdf();
        },
        1,
        this
      );
    });
  }

  public generatePdf() {
    let finalSubTotal = '';
    let totalSum = 0;
    let totalTickets = 0;

    let statusText = '';
    if (this.payout.status === 'pending') {
      statusText = 'Ausstehend';
    }
    if (this.payout.status === 'completed') {
      statusText = 'Ausbezahlt am ' + moment(this.payout.paidAt).format('DD.MM.YYYY');
    }
    let source = `
    <html>
    <style>
    body,
    html {
        width: 100%;
        height: 99.5%;
    }
    body {
        background: url('https://kunden.gastro.digital/assets/img/receipt_bg.png') top left no-repeat transparent;
        background-size: cover;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11px;
        position: relative;
        line-height: 1.35;
        padding-top: 35%;
        box-sizing: border-box;
        padding-left: 5%;
        padding-right: 5%
        }
      table {
          border-collapse: collapse; 
          width:100%
      }
      table, th, td{
          border: 1px solid #666;
          font-size: 11px;
          border: none;
      }
      table th, table td{
          padding: 5px; 
      }
      table th {
        text-align:left;
      }
      .details {
        font-size: 14px;
        font-weight: bold;
      }
    </style>
<body>
     <div>${this.clientResolver.client.name}</div>
    <br>
     <div>${this.clientResolver.client.street}</div>
     <div>${this.clientResolver.client.zipCode} ${this.clientResolver.client.location}</div>
    <br>
     <div> Auszahlung 
     ${this.payout.id} vom 
     ${moment(this.payout.createdAt).format('DD.MM.YYYY')} 
      |  Betrag: 
      ${this.returnPrice(this.payout.value)
        .toFixed(2)
        .replace('.', ',')} €  |  ${statusText}
       </div>
     <div>Leistungszeitraum:  ${this.date.startDate}  -  ${this.date.endDate}</div>
     <br>
  `;
    Object.keys(this.orders[0]).map((key: any) => {
      source += ` <br>
        <div class="details">${key}</div>
      <table> 
      <tr>
      <th> Datum </th>
      <th> Name </th>
      <th> Anzahl </th>
      <th> Betrag </th>
      </tr>
      `;
      let subTotal = 0;
      this.orders[0][key].forEach((item: any) => {
        source += `<tr>`;
        item.forEach((value: any, key: any) => {
          if (key == 2) {
            totalTickets += value;
          }
          if (key == 4) {
            subTotal += value;
            totalSum += value;
          }
          if (key !== 4) {
            source += `<td>  ${value}</td>`;
          }
        });
        source += `</tr>`;
        finalSubTotal =
          this.returnPrice(subTotal)
            .toFixed(2)
            .replace('.', ',') + ' €';
      });

      source += `<br>
      <tr class="details" style ="border-top: 2px solid black;"> 
      <td> Summe Verkäufe:  </td>
      <td> </td>
      <td> </td>
      <td>${finalSubTotal} </td>
       </tr></div>
       <br>
       </table>`;
    });
    let total =
      this.returnPrice(totalSum)
        .toFixed(2)
        .replace('.', ',') + ' €';
    source += `<br> <br>
    <div class= "details" style = "text-align:right">
    Insgesamt verkauft: ${totalTickets}
    <br>
    Summe alle Verkäufe:  ${total}
    </body> </html>`;
    this.eventsService.printTicketPayout(source).subscribe(
      (res: any) => {
        const blobdata = new Blob([new Uint8Array(res.data)], { type: 'application/pdf' });
        // const fileURL = URL.createObjectURL(blobdata);
        // window.open(fileURL, '_blank');

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blobdata);
        link.download = 'ticket-payout';
        document.body.append(link);
        link.click();
        link.remove();

        this.loading = false;
        this.snackBar.open('PDF is generated successfully.', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      },
      err => {
        const errMsg = err && err.error.msg ? err.error.msg : 'Something went';
        this.snackBar.open(errMsg, '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
      }
    );
  }

  returnPrice(price: any) {
    if (typeof price == 'string') {
      return parseFloat(price.replace(',', '.'));
    } else {
      return price || 0;
    }
  }
}

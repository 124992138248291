import { Component, OnInit } from '@angular/core';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';

import * as jspdf from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

import moment from 'moment';
import { AccountingService } from '@app/accounting/accounting.service';
import { ActivatedRoute } from '@angular/router';
moment.locale('de');

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss']
})
export class PayoutComponent implements OnInit {
  payoutData: any;
  showPayoutId: number;
  payout: {
    id: number;
    status: string;
    paidAt: any;
    createdAt: any;
    value: number;
  };

  settings: any = null;
  orders: any[] = [];
  ordersCsv: any = {};
  downloadingCsv: boolean = false;
  creds: any = {};

  pdfSums: any = {
    bar: 0,
    online: 0,
    commission: 0,
    delivery: 0,
    tip: 0
  };
  now: any = new Date();
  csvOptions: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: [
      'ID',
      'Datum',
      'Uhrzeit',
      'Betrag',
      'Provision',
      'Trinkgeld',
      'Zahlart',
      'Besteller',
      'Typ',
      'Lieferung',
      'Kontostand'
    ],
    showTitle: false,
    title: '',
    useBom: false,
    removeNewLines: true,
    keys: [
      'id',
      'dateFormatted',
      'timeFormatted',
      'subtotal',
      'commissionFee',
      'tip',
      'paymentMethodText',
      'besteller',
      'typeText',
      'deliveryFee',
      'balance'
    ]
  };
  billingCase: any = { iban: '', elv: 0 };
  loading: boolean = false;
  companyName: string = '';
  companyAddress: string = '';
  addDetails: boolean = false;
  clientId: any;
  requestPayoutProcess: boolean = false;

  constructor(
    private gastropayService: GastroPayService,
    private clientResolver: ClientResolverService,
    private credentialsService: CredentialsService,
    private accountingService: AccountingService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('clientId');
    this.getSettings();
    this.getPayouts();
    this.downloadCsv();
    this.creds = this.credentialsService.getCredentials();
    this.getBillingcase();
    // this.createInvoicePdf();
    // this.cronjobRequestPayout();
  }

  createInvoicePdf(payout: any = null) {
    /*
    const invoice = {
      betriebId: payout.clientId,
      memberId: 0, // 24703,
      createdAt: moment().format(),
      serviceBeginsAt: moment().format(),
      serviceEndsAt: moment().format(),
      netto: 0,
      steuer: 0,
      brutto: 0,
      lines: '',
      rechnungAdresse: '',
      showReceiver: 1,
      paymentMethod: 'verrechnung',
      zahlungsdatum: moment()
        .add(7, 'days')
        .format()
    };

    console.log(85, payout, invoice);
    
    this.gastropayService.createInvoicePdf(invoice).subscribe((res: any) => {
      console.log('this.createInvoicePdf', res);
    });
    */
  }

  getBillingcase() {
    this.accountingService.getBillingcase().subscribe((data: any) => {
      this.billingCase = data || { iban: '', elv: 0 };
      this.companyName = data.company;
      this.companyAddress = data.street;
      if (this.companyName === '' || this.companyAddress === '') {
        this.addDetails = true;
      }
    });
  }

  getPayouts() {
    this.gastropayService.getPayouts().subscribe((payoutData: any) => {
      this.payoutData = payoutData;
      if (payoutData.payouts) {
        payoutData.payouts.reverse();
      }
      this.requestPayoutProcess = false;
      console.log('this.payoutData', this.payoutData);
    });
  }

  requestPayout() {
    this.requestPayoutProcess = true;
    this.gastropayService.requestPayout().subscribe((payoutData: any) => {
      this.getPayouts();
    });
  }
  cronjobRequestPayout() {
    this.gastropayService.requestPayout('public').subscribe((payoutData: any) => {
      this.getPayouts();
    });
  }

  getSettings() {
    this.gastropayService.getSettings().subscribe(settings => {
      this.settings = settings || null;
      console.log('settings', this.settings);
    });
  }

  getOrders() {
    this.gastropayService.getOwnOrders(true).subscribe(orders => {
      this.orders = orders || [];
      //console.log('this.orders', this.orders);
    });
  }

  downloadCsv() {
    this.downloadingCsv = true;
    this.orders = [];
    this.ordersCsv = {};
    this.gastropayService.getOwnOrders(true).subscribe(orders => {
      console.log(173, orders);
      orders.forEach((order: any) => {
        let tempOrder: any = {};

        tempOrder.id = order.id;
        tempOrder.dateFormatted = moment(order.createdAt).format('DD.MM.YYYY');
        tempOrder.timeFormatted = moment(order.createdAt).format('H:mm');
        tempOrder.subtotal = (this.returnPrice(order.subtotal) + this.returnPrice(order.deliveryFee))
          .toFixed(2)
          .replace('.', ','); // + ' €';
        tempOrder.commissionFee = this.returnPrice(order.commissionFee)
          .toFixed(2)
          .replace('.', ','); // + ' €';
        tempOrder.tip = this.returnPrice(order.tip)
          .toFixed(2)
          .replace('.', ','); // + ' €';
        tempOrder.paymentMethodText = order.paymentMethod
          .replace('cash', 'Bar')
          .replace('gastropay', 'Gastropay')
          .replace('online', 'Online');
        tempOrder.typeText = order.type.replace('delivery', 'Lieferung').replace('takeaway', 'Abholung');
        tempOrder.deliveryFee = this.returnPrice(order.deliveryFee)
          .toFixed(2)
          .replace('.', ','); // + ' €';
        tempOrder.besteller = `${order.firstName} ${order.lastName}`;
        /*
          tempOrder.statusText = order.status
            .replace('confirmed', 'Fertig')
            .replace('pending', 'Ausstehend')
            .replace('canceled', 'Storniert');
          */
        if (order.gastropayTransaction) {
          tempOrder.balance = this.returnPrice(order.gastropayTransaction.balance)
            .toFixed(2)
            .replace('.', ','); //+ ' €';
        } else {
          tempOrder.balance = '';
        }
        if (!(order.payoutId in this.ordersCsv)) {
          this.ordersCsv[order.payoutId] = [];
        }
        if (order.status !== 'canceled' && order.status !== 'paying') {
          if (!order.gastropayTransaction) {
            this.ordersCsv[order.payoutId].push(tempOrder);
          } else {
            if (order.gastropayTransaction.clientId == order.clientId) {
              this.ordersCsv[order.payoutId].push(tempOrder);
            }
          }
        }
      });
      //console.log('this.ordersCsv', this.ordersCsv);
    });
  }

  downloadPdf(payout: any) {
    this.loading = true;
    this.pdfSums = {
      bar: 0,
      online: 0,
      commission: 0,
      delivery: 0,
      tip: 0
    };
    this.orders = [];
    this.payout = payout || { id: 0 };
    this.gastropayService.getPayoutOrders(true, payout.id).subscribe(orders => {
      orders.forEach((order: any) => {
        if (order.payoutId === payout.id) {
          console.log(order);
          let tempOrder: any[] = [];

          // ['ID','Datum','Uhrzeit','Betrag','Gebühr','Zahlart','Typ','Kontostand'],
          tempOrder.push(order.id);
          tempOrder.push(moment(order.createdAt).format('DD.MM.YYYY') + ' ' + moment(order.createdAt).format('H:mm'));
          //tempOrder.push(moment(order.createdAt).format('H:mm'));
          tempOrder.push(
            (this.returnPrice(order.subtotal) + this.returnPrice(order.deliveryFee)).toFixed(2).replace('.', ',') + ' €'
          );
          tempOrder.push(
            this.returnPrice(order.deliveryFee)
              .toFixed(2)
              .replace('.', ',') + ' €'
          );
          tempOrder.push(
            this.returnPrice(order.tip)
              .toFixed(2)
              .replace('.', ',') + ' €'
          );

          tempOrder.push(
            order.paymentMethod
              .replace('cash', 'Bar')
              .replace('gastropay', 'Gastropay')
              .replace('online', 'Online')
          );
          //tempOrder.push(order.type.replace('delivery', 'Lieferung').replace('takeaway', 'Abholung'));
          /*
          tempOrder.push(
            order.status
              .replace('confirmed', 'Fertig')
              .replace('pending', 'Ausstehend')
              .replace('canceled', 'Storniert')
          );
          if (order.gastropayTransaction) {
            tempOrder.push(
              this.returnPrice(order.gastropayTransaction.balance)
                .toFixed(2)
                .replace('.', ',') + ' €'
            );
          } else {
            tempOrder.push('');
          }
          */
          // tempOrder.push(`${order.firstName} ${order.lastName}`);
          tempOrder.push(
            this.returnPrice(order.commissionFee)
              .toFixed(2)
              .replace('.', ',') + ' €'
          );

          if (order.status !== 'canceled' && order.status !== 'paying') {
            if (order.deliveryFee) {
              this.pdfSums.delivery += this.returnPrice(order.deliveryFee);
            }
            if (order.tip) {
              this.pdfSums.tip += this.returnPrice(order.tip);
            }
            if (!order.gastropayTransaction) {
              this.pdfSums.commission += this.returnPrice(order.commissionFee);
              if (order.paymentMethod == 'cash' || order.paymentMethod == 'Bar') {
                this.pdfSums.bar += this.returnPrice(order.subtotal) + this.returnPrice(order.deliveryFee);
              } else {
                this.pdfSums.online += this.returnPrice(order.subtotal) + this.returnPrice(order.deliveryFee);
              }
              this.orders.push(tempOrder);
            } else {
              if (order.gastropayTransaction.clientId == order.clientId) {
                this.pdfSums.commission += this.returnPrice(order.commissionFee);
                if (order.paymentMethod == 'cash' || order.paymentMethod == 'Bar') {
                  this.pdfSums.bar += this.returnPrice(order.subtotal) + this.returnPrice(order.deliveryFee);
                } else {
                  this.pdfSums.online += this.returnPrice(order.subtotal) + this.returnPrice(order.deliveryFee);
                }
                this.orders.push(tempOrder);
              }
            }
          }
        }
      });

      this.orders.push([
        '--------',
        '------------------------',
        '------------',
        '------------',
        '------------',
        '-------------',
        '------------------------'
      ]);
      this.orders.push(['Summe', 'Barzahlung', this.pdfSums.bar.toFixed(2).replace('.', ',') + ' €', '', '', '', '']);
      this.orders.push([
        'Summe',
        'Onlinezahlung',
        this.pdfSums.online.toFixed(2).replace('.', ',') + ' €',
        '',
        '',
        '',
        ''
      ]);
      this.orders.push([
        'Summe',
        'Provision',
        this.pdfSums.commission.toFixed(2).replace('.', ',') + ' €',
        '',
        '',
        '',
        ''
      ]);
      this.orders.push([
        'Summe',
        'Lieferung',
        this.pdfSums.delivery.toFixed(2).replace('.', ',') + ' €',
        '',
        '',
        '',
        ''
      ]);
      this.orders.push(['Summe', 'Trinkgeld', this.pdfSums.tip.toFixed(2).replace('.', ',') + ' €', '', '', '', '']);

      setTimeout(
        function(_this: any) {
          _this.generatePdf();
        },
        1,
        this
      );
    });
  }

  public generatePdf() {
    let jspdfClass: any = jspdf;
    let doc: jspdf = new jspdfClass('p', 'mm', 'a4'); // A4 size page of PDF

    let img = new Image();
    img.src = 'assets/img/receipt_bg.png';

    let img2 = new Image();
    img2.src = 'assets/img/receipt_bg_clear.png';

    doc.addImage(img, 'png', 0, 0, 210, 297);
    doc.internal.events.subscribe('addPage', function() {
      let pageSize = doc.internal.pageSize;
      doc.addImage(img2, 'png', 0, 0, pageSize.width, pageSize.height);
    });

    doc.setFontSize(14);
    let statusText = '';
    if (this.payout.status === 'pending') {
      statusText = 'Ausstehend';
    }
    if (this.payout.status === 'completed') {
      statusText = 'Ausbezahlt am ' + moment(this.payout.paidAt).format('DD.MM.YYYY');
    }

    doc.setFontSize(11);
    doc.text(
      'Auszahlung ' +
        this.payout.id +
        ' vom ' +
        moment(this.payout.createdAt).format('DD.MM.YYYY') +
        '  |  Betrag: ' +
        this.returnPrice(this.payout.value)
          .toFixed(2)
          .replace('.', ',') +
        ' €   |  ' +
        statusText,
      16,
      75
    );
    //console.log('this.clientResolver', this.orders, this.orders[0].createdAt, this.orders[this.orders.length-1].createdAt);
    doc.text('Leistungszeitraum: ' + this.orders[this.orders.length - 7][1] + ' - ' + this.orders[0][1], 16, 81);

    doc.text(this.clientResolver.client.name, 16, 43);
    doc.text('', 16, 49);
    doc.text(this.clientResolver.client.street, 16, 55);
    doc.text(this.clientResolver.client.zipCode + ' ' + this.clientResolver.client.location, 16, 61);
    doc.setFontSize(10);
    doc.setTextColor(100);

    let tableHeader: any[] = [
      [
        'ID',
        'Datum',
        /*n'Uhrzeit', */ 'Betrag',
        'Lieferung',
        'Trinkgeld',
        'Zahlart',
        /* 'Typ', 'Kontostand' */ 'Provision'
      ]
    ];

    (doc as any).autoTable({
      bodyStyles: {
        valign: 'top'
      },
      styles: {
        overflow: 'linebreak'
      },
      head: tableHeader,
      body: this.orders,
      footer: tableHeader,
      theme: 'plain',
      startY: 90,
      startX: 0,
      didDrawCell: (data: any) => {
        // console.log(data.column.index)
      }
    });

    doc.text(
      'Barzahlung: ' +
        this.pdfSums.bar.toFixed(2).replace('.', ',') +
        ' €  |  Onlinezahlung: ' +
        this.pdfSums.online.toFixed(2).replace('.', ',') +
        ' €  |  Provision: ' +
        this.pdfSums.commission.toFixed(2).replace('.', ',') +
        ' €  |  Lieferung: ' +
        this.pdfSums.delivery.toFixed(2).replace('.', ',') +
        ' €  |  Trinkgeld: ' +
        this.pdfSums.tip.toFixed(2).replace('.', ',') +
        ' €',
      16,
      290
    );

    this.loading = false;
    doc.save('payout_orders_list.pdf');
  }

  returnPrice(price: any) {
    //console.log('price',price);
    if (typeof price == 'string') {
      return parseFloat(price.replace(',', '.'));
    } else {
      return price || 0;
    }
  }

  filterOrders(orders: any[] = [], payout: any) {
    //console.log('filterOrders', this.ordersCsv);
    if (!orders.length && !this.downloadingCsv) {
      this.downloadingCsv = true;
      //this.downloadCsv(payout);
      return this.ordersCsv;
    }
    return orders;
  }
}

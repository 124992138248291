import { Component, OnInit } from '@angular/core';
import { GastroPayService } from '@app/gastro-pay/gastro-pay.service';
import { ClientResolverService } from '@app/core/client-resolver.service';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as jspdf from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

import moment from 'moment';
import { AccountingService } from '@app/accounting/accounting.service';
moment.locale('de');

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss']
})
export class PayoutComponent implements OnInit {
  payoutData: any;
  showPayoutId: number;
  payout: {
    id: number;
    status: string;
    paidAt: any;
    createdAt: any;
    value: number;
  };

  settings: any = null;
  orders: any[] = [];
  ordersCsv: any = {};
  downloadingCsv: boolean = false;
  creds: any = {};
  loading: boolean = false;
  pdfSums: any = {
    bar: 0,
    online: 0,
    commission: 0
  };
  now: any = new Date();
  csvOptions: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: ['ID', 'Datum', 'Uhrzeit', 'Betrag', 'Gebühr', 'Reserviert für', 'Gast', 'Typ', 'Kontostand'],
    showTitle: false,
    title: '',
    useBom: false,
    removeNewLines: true,
    keys: [
      'id',
      'dateFormatted',
      'timeFormatted',
      'subtotal',
      'commissionFee',
      'paymentMethodText',
      'besteller',
      'typeText',
      'balance'
    ]
  };
  billingCase: any = { iban: '', elv: 0 };
  requestPayoutProcess: boolean = false;

  constructor(
    private gastropayService: GastroPayService,
    private clientResolver: ClientResolverService,
    private credentialsService: CredentialsService,
    private accountingService: AccountingService,
    private snackBar: MatSnackBar
  ) {
    // console.log('this.clientResolver', this.clientResolver);
  }

  ngOnInit() {
    this.getSettings();
    this.getPayouts();
    this.downloadCsv();
    this.getBillingcase();
    this.creds = this.credentialsService.getCredentials();
    // this.cronjobRequestPayout();
  }

  getSettings() {
    this.gastropayService.getSettings().subscribe(settings => {
      this.settings = settings || null;
      console.log('settings', this.settings);
    });
  }

  getBillingcase() {
    this.accountingService.getBillingcase().subscribe((data: any) => {
      this.billingCase = data || { iban: '', elv: 0 };
    });
  }

  getPayouts() {
    this.gastropayService.getPayouts('/reservations').subscribe((payoutData: any) => {
      console.log(88, payoutData);

      this.payoutData = payoutData;
      if (payoutData.payouts) {
        payoutData.payouts.reverse();
      }
      this.requestPayoutProcess = false;
    });
  }

  requestPayout() {
    this.requestPayoutProcess = true;
    this.gastropayService.requestPayout('reservation').subscribe((payoutData: any) => {
      this.getPayouts();
    });
  }
  cronjobRequestPayout() {
    this.gastropayService.requestPayout('public').subscribe((payoutData: any) => {
      this.getPayouts();
    });
  }

  getTransactions() {
    this.gastropayService.getReservationsTransactions().subscribe(transactions => {
      this.orders = transactions || [];
    });
  }

  downloadCsv() {
    this.downloadingCsv = true;
    this.orders = [];
    this.ordersCsv = {};
    this.gastropayService.getReservationsTransactions().subscribe(orders => {
      //console.log('orders',orders);
      orders.forEach((order: any) => {
        if (order.type != 'payout') {
          let tempOrder: any = {};

          tempOrder.id = order.id;
          tempOrder.dateFormatted = moment(order.createdAt).format('DD.MM.YYYY');
          tempOrder.timeFormatted = moment(order.createdAt).format('H:mm');
          tempOrder.subtotal =
            (order.type == 'refund' ? '-' : '') +
            this.returnPrice(order.value)
              .toFixed(2)
              .replace('.', ',') +
            ' €';
          tempOrder.commissionFee =
            this.returnPrice(order.commissionFee)
              .toFixed(2)
              .replace('.', ',') + ' €';
          tempOrder.paymentMethodText =
            order.Reservation && order.Reservation.reservedFor
              ? moment(order.Reservation.reservedFor).format('DD.MM.YYYY - H:mm')
              : ''; // order.type;
          /*
            .replace('cash', 'Bar')
            .replace('gastropay', 'Gastropay')
            .replace('online', 'Online'); */
          tempOrder.typeText = order.type
            .replace('payment', 'Anzahlung')
            .replace('refund', 'Erstattung')
            .replace('payout', 'Auszahlung');
          if (order.Reservation) {
            tempOrder.besteller = `${order.Reservation.guestData.name}`;
          } else {
            tempOrder.besteller = 'Gast';
          }
          /*
            tempOrder.statusText = order.status
              .replace('confirmed', 'Fertig')
              .replace('pending', 'Ausstehend')
              .replace('canceled', 'Storniert');
            */
          tempOrder.balance =
            this.returnPrice(order.balance)
              .toFixed(2)
              .replace('.', ',') + ' €';
          if (!(order.payoutId in this.ordersCsv)) {
            this.ordersCsv[order.payoutId] = [];
          }
          this.ordersCsv[order.payoutId].push(tempOrder);
        }
      });
      console.log('this.ordersCsv', this.ordersCsv);
    });
  }

  downloadPdf(payout: any) {
    this.loading = true;
    this.pdfSums = {
      bar: 0,
      online: 0,
      commission: 0
    };
    this.orders = [];
    this.payout = payout || { id: 0 };
    this.gastropayService.getPayoutReservations(payout.id).subscribe(orders => {
      orders.reverse();
      orders.forEach((order: any) => {
        if (order.payoutId === payout.id && order.type !== 'payout') {
          let tempOrder: any[] = [];

          // ['ID','Datum','Uhrzeit','Betrag','Gebühr','Zahlart','Typ','Kontostand'],
          tempOrder.push(order.id);
          tempOrder.push(moment(order.createdAt).format('DD.MM.YYYY') + ' ' + moment(order.createdAt).format('H:mm'));
          //tempOrder.push(moment(order.createdAt).format('H:mm'));
          tempOrder.push(
            (order.type == 'refund' ? '-' : '') +
              this.returnPrice(order.value)
                .toFixed(2)
                .replace('.', ',') +
              ' €'
          );
          this.pdfSums.commission += this.returnPrice(order.commissionFee);
          tempOrder.push(
            this.returnPrice(order.commissionFee)
              .toFixed(2)
              .replace('.', ',') + ' €'
          );

          this.pdfSums.online += this.returnPrice(order.value * (order.type == 'refund' ? -1 : 1));

          tempOrder.push(
            order.type
              .replace('payment', 'Anzahlung')
              .replace('refund', 'Erstattung')
              .replace('payout', 'Auszahlung')
          );
          //tempOrder.push(order.type.replace('delivery', 'Lieferung').replace('takeaway', 'Abholung'));
          /*
          tempOrder.push(
            order.status
              .replace('confirmed', 'Fertig')
              .replace('pending', 'Ausstehend')
              .replace('canceled', 'Storniert')
          );
          if (order.gastropayTransaction) {
            tempOrder.push(
              this.returnPrice(order.gastropayTransaction.balance)
                .toFixed(2)
                .replace('.', ',') + ' €'
            );
          } else {
            tempOrder.push('');
          }
          */
          if (order.Reservation) {
            tempOrder.push(`${order.Reservation.guestData.name}`);
          } else {
            tempOrder.push('Gast');
          }
          this.orders.push(tempOrder);
        }
      });
      //console.log('this.orders', this.orders, payout);
      if (this.orders && this.orders.length > 0) {
        setTimeout(
          function(_this: any) {
            _this.generatePdf();
          },
          1,
          this
        );
      } else {
        this.snackBar.open('No data available.', '', {
          duration: 2000,
          panelClass: ['snackbar-error']
        });
        this.loading = false;
        return;
      }
    });
  }

  public generatePdf() {
    let jspdfClass: any = jspdf;
    let doc: jspdf = new jspdfClass('p', 'mm', 'a4'); // A4 size page of PDF

    doc.setFontSize(14);
    let statusText = '';
    if (this.payout.status === 'pending') {
      statusText = 'Ausstehend';
    }
    if (this.payout.status === 'completed') {
      statusText = 'Ausbezahlt am ' + moment(this.payout.paidAt).format('DD.MM.YYYY');
    }

    doc.setFontSize(12);
    doc.text(
      'Auszahlung ' +
        this.payout.id +
        ' vom ' +
        moment(this.payout.createdAt).format('DD.MM.YYYY') +
        '  |  Betrag: ' +
        this.returnPrice(this.payout.value)
          .toFixed(2)
          .replace('.', ',') +
        ' €   |  ' +
        statusText,
      11,
      8
    );
    //console.log('this.clientResolver', this.orders, this.orders[0].createdAt, this.orders[this.orders.length-1].createdAt);
    doc.text('Leistungszeitraum: ' + this.orders[this.orders.length - 1][1] + ' - ' + this.orders[0][1], 11, 13);

    doc.text(this.clientResolver.client.name, 11, 25);
    doc.text('', 11, 31);
    doc.text(this.clientResolver.client.street, 11, 37);
    doc.text(this.clientResolver.client.zipCode + ' ' + this.clientResolver.client.location, 11, 43);
    doc.setFontSize(10);
    doc.setTextColor(100);

    let tableHeader: any[] = [
      ['ID', 'Datum', /*n'Uhrzeit', */ 'Betrag', 'Gebühr', 'TYp', /* 'Typ', 'Kontostand' */ 'Gast']
    ];

    (doc as any).autoTable({
      bodyStyles: {
        valign: 'top'
      },
      styles: {
        overflow: 'linebreak'
      },
      head: tableHeader,
      body: this.orders,
      theme: 'plain',
      startY: 51,
      didDrawCell: (data: any) => {
        // console.log(data.column.index)
      }
    });

    doc.text(
      'Summe bar: ' +
        this.pdfSums.bar.toFixed(2).replace('.', ',') +
        ' €  |   Summe online: ' +
        this.pdfSums.online.toFixed(2).replace('.', ',') +
        ' €  |  Summe Gebühren: ' +
        this.pdfSums.commission.toFixed(2).replace('.', ',') +
        ' €',
      11,
      295
    );
    this.loading = false;
    doc.save('payout_orders_list.pdf');
  }

  returnPrice(price: any) {
    //console.log('price',price);
    if (typeof price == 'string') {
      return parseFloat(price.replace(',', '.'));
    } else {
      return price || 0;
    }
  }

  filterOrders(orders: any[] = [], payout: any) {
    //console.log('filterOrders', this.ordersCsv);
    if (!orders.length && !this.downloadingCsv) {
      this.downloadingCsv = true;
      //this.downloadCsv(payout);
      return this.ordersCsv;
    }
    return orders;
  }
}
